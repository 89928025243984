import store from '@/store'
import jwtConfig from './jwtConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtConfig }

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        store.state.app.isContentLoading = true
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  get(endPoint, ...args) {
    return this.axiosIns.get(endPoint, ...args)
  }

  post(endPoint, ...args) {
    return this.axiosIns.post(endPoint, ...args)
  }

  put(endPoint, ...args) {
    return this.axiosIns.put(endPoint, ...args)
  }

  delete(endPoint, ...args) {
    return this.axiosIns.delete(endPoint, ...args)
  }

  patch(endPoint, ...args) {
    return this.axiosIns.patch(endPoint, ...args)
  }

  sendResetPasswordEmail(...args) {
    return this.axiosIns.post('auth/send-reset-password-email', ...args)
  }

  validateResetPasswordToken(...args) {
    return this.axiosIns.post('auth/validate-reset-password-token', ...args)
  }

  resetPassword(...args) {
    return this.axiosIns.post('auth/reset-password', ...args)
  }

  activateUser(...args) {
    return this.axiosIns.post('auth/activate-user', ...args)
  }
}
